export const TITLE_APP = "Wilderness International Widget |";
export const isBrowserLocal = () => typeof localStorage !== "undefined";

export enum LanguageCode {
    EN = 'EN',
    DE = 'DE',
}

export const WIDGET_TYPE = {
		DONATION: 'donation',
		TOTAL: 'total'
}

export const DEFAULT_HEADLINE: any = {
		DONATION: {
			EN: 'Last Donation',
			DE: 'Letzte Spende'
		},
		TOTAL: {
			EN: 'Total',
			DE: 'Summe'
		}
}

export const LanguageList = [
	{ name: LanguageCode.EN, code: LanguageCode.EN },
	{ name: LanguageCode.DE, code: LanguageCode.DE }
];

export const getLanguageDefault = () => {
    if(isBrowserLocal()) {
      const getLanguage = localStorage.getItem('language_code') || 'de';
      return LanguageList.find(lan => lan.code === getLanguage.toUpperCase()) || LanguageList[0];
    } 
    return LanguageList[0];
}

export enum DISPLAY_CODE {
	SQM = 'sqm',
	AMOUNT = 'amount',
	co2 = 'co2'
}

export enum ALIGN_CODE {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right'
}

export const CURRENCY_CODE = {
  EUR: 'EUR',
  CAD: 'CAD',
  CHF: 'CHF'
}

export enum VISIBILITY_CODE  {
	ALL = '',
	NAME = 'name',
	VALUE = 'value',
}

export const convertNumberToLocal = (parameters : any, last_donation: any) => {
	if(last_donation) {
		switch (parameters?.displayValue) {
			case DISPLAY_CODE.SQM : return (last_donation?.forest_size?.toLocaleString(parameters?.language?.toLowerCase()) || 0) + ' m²';
			case DISPLAY_CODE.AMOUNT : return (last_donation?.amount?.toLocaleString(parameters?.language?.toLowerCase()) || 0) + ` ${last_donation?.currency?.toUpperCase() || CURRENCY_CODE.EUR}`; 
			case DISPLAY_CODE.co2 : return (last_donation?.co2?.toLocaleString(parameters?.language?.toLowerCase()) || 0) + " kg";
			default: return 'N/A';
		}
	} else {
		return 'N/A';
	}
}

export const convertNumberToLocalTotal = (parameters : any, widgetData: any) => {
	if(widgetData) {
		switch (parameters?.displayValue) {
			case DISPLAY_CODE.SQM : return (widgetData?.summary?.protected_size?.toLocaleString(parameters?.language?.toLowerCase()) || 0) + ' m²';
			case DISPLAY_CODE.AMOUNT : return (widgetData?.summary?.amount_in_euro?.toLocaleString(parameters?.language?.toLowerCase()) || 0) + ` ${CURRENCY_CODE.EUR}`; 
			case DISPLAY_CODE.co2 : return (widgetData?.summary?.protected_kg_co2?.toLocaleString(parameters?.language?.toLowerCase()) || 0) + " kg";
			default: return 'N/A';
		}
	} else {
		return 'N/A';
	}
}

export const loadGoogleFont = (fontName: string) => {
    const style = document.createElement('style');
    style.innerHTML = `
      @import url('https://fonts.googleapis.com/css2?family=${fontName.replace(' ', '+')}:wght@300;400;500;600;700&display=swap');
    `;
    document.head.appendChild(style);
};