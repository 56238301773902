import { ALIGN_CODE, DEFAULT_HEADLINE, DISPLAY_CODE, LanguageCode, VISIBILITY_CODE, WIDGET_TYPE } from "../utils";
import sanitize from 'sanitize-html';

export default class ParametersWidget {
  private language: string;
  private displayValue: string;
  private align: string;
  private backgroundColor: string;
  private fontFamily: string;
  private fontSize: number;
  private textColor: string;
  private headline: any;
  private headlineWeight: number;
  private donorNameWeight: number;
  private valueWeight: number;
  private identifier: string;
  private visibility: string;

  constructor(location: string, widgetType: string) {
    let parameters: any = new URLSearchParams(location);
    const newParams = new URLSearchParams();
    for (let [key, value] of parameters.entries()) {
      newParams.set(key.toLowerCase(), value);
    }
    
    parameters = newParams;
    
    this.language = this.getLanguage(parameters.get("language")?.toLowerCase() || LanguageCode.DE.toLowerCase());
    this.displayValue = this.getDisplayValue(parameters.get("displayvalue")?.toLowerCase() || DISPLAY_CODE.SQM);
    this.align = this.getAlign(parameters.get("align")?.toLowerCase() || ALIGN_CODE.CENTER);
    this.backgroundColor = parameters.get("backgroundcolor") || 'transparent';
    this.fontFamily = parameters.get("fontfamily") || 'Arial';
    this.fontSize = isNaN(parseInt(parameters.get("fontsize") || '20')) ? 20 : parseInt(parameters.get("fontsize") || '20');
    this.textColor = parameters.get("fontcolor") || '#000000';
    this.headline = this.getHeadline(parameters.get("headline")?.toLowerCase() || (widgetType === WIDGET_TYPE.DONATION ? DEFAULT_HEADLINE.DONATION[this.language.toUpperCase() || LanguageCode.DE] : DEFAULT_HEADLINE.TOTAL[this.language.toUpperCase() || LanguageCode.DE]), widgetType);
    this.headlineWeight = isNaN(parseInt(parameters.get("headlineweight") || '400')) ? 400 : parseInt(parameters.get("headlineweight") || '400');
    this.donorNameWeight = isNaN(parseInt(parameters.get("donornameweight") || '700')) ? 700 : parseInt(parameters.get("donornameweight") || '700');
    this.valueWeight = isNaN(parseInt(parameters.get("valueweight") || '700')) ? 700 : parseInt(parameters.get("valueweight") || '700');
    this.identifier = parameters.get("identifier") || '';
    this.visibility = this.getVisibility(parameters.get("visibility")?.toLowerCase()) || '';
  }

  public getLanguage(value: string): string {
    if (Object.values(LanguageCode).includes(value.toUpperCase() as LanguageCode)) {
      return value as LanguageCode;
    }
  
    return LanguageCode.DE.toLowerCase();
  }

  public getDisplayValue(value: string): string {
    if (Object.values(DISPLAY_CODE).includes(value.toLowerCase() as DISPLAY_CODE)) {
      return value as DISPLAY_CODE;
    }
  
    return DISPLAY_CODE.SQM;
  }

  public getAlign(value: string): string {
    if (Object.values(ALIGN_CODE).includes(value.toLowerCase() as ALIGN_CODE)) {
      return value as ALIGN_CODE;
    }
  
    return ALIGN_CODE.CENTER;
  }

  public getVisibility(value: string): string {
    if (Object.values(VISIBILITY_CODE).includes(value as VISIBILITY_CODE)) {
      return value as VISIBILITY_CODE;
    }
  
    return VISIBILITY_CODE.ALL;
  }

  public getBackgroundColor() {
    return this.backgroundColor || 'transparent';
  }

  public getFontFamily(): string {
    return this.fontFamily || 'Arial';
  }


  public getFontSize(): number {
    return this.fontSize || 20;
  }

  public getTextColor(): string {
    return this.textColor || '#000000';
  }

  public getHeadline(value: string, widgetType: string): boolean | string {
    if (value === 'true') {
      return (widgetType === WIDGET_TYPE.DONATION ? DEFAULT_HEADLINE.DONATION[this.language.toUpperCase() || LanguageCode.DE] : DEFAULT_HEADLINE.TOTAL[this.language.toUpperCase() || LanguageCode.DE]);
    }

    if(value === 'false') {
      return false;
    }

    if (typeof value === 'string') {
      return sanitize(value || (widgetType === WIDGET_TYPE.DONATION ? DEFAULT_HEADLINE.DONATION[this.language.toUpperCase() || LanguageCode.DE] : DEFAULT_HEADLINE.TOTAL[this.language.toUpperCase() || LanguageCode.DE]));
    }
  
    return false;
  }

  public getHeadlineWeight(): number {
    return this.headlineWeight || 400;
  }

  public getDonorNameWeight(): number {
    return this.donorNameWeight || 700;
  }

  public getValueWeight(): number {
    return this.valueWeight || 700;
  }
}
